import kitchen1 from "../../assets/images/kitchen/kitchen1.webp";
import kitchen2 from "../../assets/images/kitchen/kitchen2.webp";
import kitchen3 from "../../assets/images/kitchen/kitchen3.webp";
import kitchen4 from "../../assets/images/kitchen/kitchen4.webp";
import kitchen5 from "../../assets/images/kitchen/kitchen5.webp";
import kitchen6 from "../../assets/images/kitchen/kitchen6.webp";
import hallway1 from "../../assets/images/hallway/hallway1.webp";
import hallway2 from "../../assets/images/hallway/hallway2.webp";
import hallway3 from "../../assets/images/hallway/hallway3.webp";
import lounge1 from "../../assets/images/lounge/lounge1.webp";
import lounge2 from "../../assets/images/lounge/lounge2.webp";
import lounge3 from "../../assets/images/lounge/lounge3.webp";
import lounge4 from "../../assets/images/lounge/lounge4.webp";
import lounge5 from "../../assets/images/lounge/lounge5.webp";
import lounge6 from "../../assets/images/lounge/lounge6.webp";
import bedroom1 from "../../assets/images/bedroom/bedroom1.webp";
import bedroom2 from "../../assets/images/bedroom/bedroom2.webp";
import bedroom3 from "../../assets/images/bedroom/bedroom3.webp";
import bedroom4 from "../../assets/images/bedroom/bedroom4.webp";
import bathroom1 from "../../assets/images/bathroom/bathroom1.webp";
import bathroom2 from "../../assets/images/bathroom/bathroom2.webp";
import shower1 from "../../assets/images/shower/shower1.webp";
import shower2 from "../../assets/images/shower/shower2.webp";

export const data = [
  {
    title: "кухня-столовая",
    images: [kitchen1, kitchen2, kitchen3, kitchen4, kitchen5, kitchen6],
    description:
      'Наливной эпоксидный пол !Remmers, структурированная декоративная штукатурка !Caparol, керамическая плитка !Kerama !Marazzi, гипсовые светильники !Maytoni, кухня !ZOV, фасады !Fenix, обеденный стол !индивидуального !изготовления, стул !Mexo, стул !Qeeboo !Rabbit, настольная лампа !винтаж, сантехника !Teka, электрофурнитура !Jung !LS !990, поднос !Diesel-Seletti !Machine !Collection, холст "La Mouette" !Greet !en !Peter !de !Groote, декор !Sia',
  },
  {
    title: "прихожая",
    images: [hallway1, hallway2, hallway3],
    description:
      "Наливной эпоксидный пол !Remmers, структурированная декоративная штукатурка !Caparol, гипсовые светильники !Maytoni, перфорированные панели и двери !индивидуального !изготовления, электрофурнитура !Jung !LS !990, гардеробная и тумба индивидуального изготовления !Белсимплит, пуф !Mexo",
  },
  {
    title: "гостиная",
    images: [lounge1, lounge2, lounge3, lounge4, lounge5, lounge6],
    description:
      'Наливной эпоксидный пол !Remmers, структурированная декоративная штукатурка !Caparol, гипсовые светильники !Maytoni, дверь скрытого монтажа !Vladveri, электрофурнитура !Jung !LS !990, виниловая станция индивидуального изготовления !Белсимплит, диван, кофейный столик и пуф !Mexo, ковер !Amini, холст "#12 JAN" !Андрей !Пичушкин',
  },
  {
    title: "спальня",
    images: [bedroom1, bedroom2, bedroom3, bedroom4],
    description:
      'Наливной эпоксидный пол !Remmers, структурированная декоративная штукатурка !Caparol, гипсовые светильники !Maytoni, электрофурнитура !Jung !LS !990, столик !Arketipo !Joystick, лампа !Seletti! With !Me !Lamp, холст "#21 OCT" !Андрей !Пичушкин, декор !Sia, кресло !Flexform !Tessa, лампа Karman !Pirla, гардеробная индивидуального изготовления !Белсимплит, софтбокс !Maytoni',
  },
  {
    title: "ванная",
    images: [bathroom1, bathroom2],
    description:
      "Наливной эпоксидный пол !Remmers, структурированная декоративная штукатурка !Caparol, алюминиевые двери !Profildoors, электрофурнитура !Jung !LS !990 !Les !Couleurs® !Le !Corbusier !Design, керамическая плитка !Kerama !Marazzi, полотенцесушитель !Сунержа, сантехника !Iddis, люстра !Lightstar, столик !Mexo, столешница и полки из нержавеющей стали !индивидуального !изготовления, сантехника !Iddis, тумба индивидуального изготовления !Белсимплит, декор !Sia",
  },
  {
    title: "душевая",
    images: [shower1, shower2],
    description:
      "Наливной эпоксидный пол !Remmers, структурированная декоративная штукатурка !Caparol, гипсовые светильники !Maytoni, керамическая плитка !Kerama !Marazzi, шкаф индивидуального изготовления !Белсимплит, полки и столешница из нержавеющей стали !индивидуального !изготовления, дверь скрытого монтажа !Vladveri, электрофурнитура !Jung !LS !990, сантехника !Iddis, лампа !Karman !Abachina",
  },
];
