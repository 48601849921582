import { Content } from "../Content/Content";

import complex1 from "../../assets/images/complex/complex1.webp";
import complex2 from "../../assets/images/complex/complex2.webp";
import complex3 from "../../assets/images/complex/complex3.webp";
import complex4 from "../../assets/images/complex/complex4.webp";
import complex5 from "../../assets/images/complex/complex5.webp";
import complex6 from "../../assets/images/complex/complex6.webp";
import complex7 from "../../assets/images/complex/complex7.webp";
import complex8 from "../../assets/images/complex/complex8.webp";
import styled from "styled-components";

const data = {
  title: "Жк “Зодиак”",
  images: [
    complex1,
    complex2,
    complex3,
    complex4,
    complex5,
    complex6,
    complex7,
    complex8,
  ],
  description:
    'в доме имеется два высокоскоростных лифта (грузовой и пассажирский), инвалидный пандус, видео домофон, комната для мытья лап животных, камеры видео наблюдения.Расположение дома идеально для комфортной городской жизни, работы и отдыха. Вся инфраструктура находится в шаговой доступности:-Детские сады с бассейном-Современная школа с бассейном-Областная детская и взрослая больницы-Медицинский центр "Евромедика"-Множество магазинов-Автобусные остановки с хорошо организованным транспортным сообщением-Около дома есть детская площадка, лесной массив, прогулочные тропы для любителей активного отдыха, так и для любителей неторопливых пеших прогулок на свежем воздухе',
};

export const ResidentialComplex = () => {
  return (
    <S.Container>
      <Content {...data} />
    </S.Container>
  );
};

namespace S {
  export const Container = styled.div`
    max-width: 1440px;
    margin: 0 auto;
  `;
}
