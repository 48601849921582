import { HTMLProps } from "react";
import styled from "styled-components";
import { Social } from "../common/Social";

export const Header = ({ ...props }: HTMLProps<HTMLDivElement>) => {
  return (
    <S.Wrapper {...props}>
      <Social />
    </S.Wrapper>
  );
};

namespace S {
  export const Wrapper = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 130px;
    padding: 0 130px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  `;
}
