import styled from "styled-components";

import plan from "../../assets/images/plan.webp";

const CONTENT = [
  {
    title: "ПУБЛИЧНОЕ",
    chapters: [
      {
        count: 1,
        title: "Прихожая",
        square: "5,52 M2",
      },
      {
        count: 2,
        title: "Общая Гардеробная",
        square: "5,09 M2",
      },
      {
        count: 3,
        title: "Душевая",
        square: "3.77 M2",
      },
      {
        count: 4,
        title: "Студия",
        square: "53,82 M2",
      },
    ],
  },
  {
    title: "ПРИВАТНОЕ",
    chapters: [
      {
        count: 5,
        title: "Мастер-Спальня",
        square: "18,37 M2",
      },
      {
        count: 6,
        title: "Мастер-Гардеробная",
        square: "3,56 M2",
      },
      {
        count: 7,
        title: "Мастер-Ванная",
        square: "4,94 M2",
      },
    ],
  },
];

export const Plan = () => {
  return (
    <S.Container>
      <S.Image alt="plan" loading="eager" src={plan} />
      <S.ContentContainer>
        {CONTENT.map((item, index) => (
          <S.Content key={index}>
            <S.Title>{item.title}</S.Title>
            <S.ChaptersContainer>
              {item.chapters.map((chapter, index) => (
                <S.ChapterContainer key={index}>
                  <S.LeftSide>
                    <S.Count>{chapter.count}</S.Count>
                    <S.ChapterTitle>{chapter.title}</S.ChapterTitle>
                  </S.LeftSide>
                  <S.Square>{chapter.square}</S.Square>
                </S.ChapterContainer>
              ))}
            </S.ChaptersContainer>
          </S.Content>
        ))}
      </S.ContentContainer>
    </S.Container>
  );
};

namespace S {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 65px 70px;
    gap: 20px;
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: 1024px) {
      padding: 24px 0;
      gap: 0;
    }
  `;

  export const Image = styled.img`
    width: 100%;

    @media (max-width: 1024px) {
      padding: 24px 0;
    }
  `;

  export const ContentContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 60px;

    @media (max-width: 1024px) {
      gap: 20px;
      padding: 24px 16px;
    }
  `;

  export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 1296px) {
      flex: auto;
    }

    @media (max-width: 1024px) {
      gap: 8px;
    }
  `;

  export const Text = styled.span`
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    line-height: 150%;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  `;

  export const Title = styled(Text)`
    font-weight: 700;
    text-transform: capitalize;
    text-align: center;

    @media (max-width: 1295px) {
      text-align: start;
    }
  `;

  export const ChaptersContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    gap: 12px;

    @media (max-width: 1024px) {
      padding: 0;
    }
  `;

  export const ChapterContainer = styled.div`
    display: flex;
    gap: 166px;
    justify-content: space-between;

    @media (max-width: 768px) {
      gap: 0;
    }
  `;

  export const LeftSide = styled.div`
    display: flex;
    gap: 8px;
  `;

  export const Count = styled(Text)`
    font-weight: 400;
    text-transform: capitalize;
    padding: 0 3px;
  `;

  export const ChapterTitle = styled(Text)`
    font-weight: 300;
    text-transform: capitalize;
  `;

  export const Square = styled(Text)`
    font-weight: 400;
    text-transform: capitalize;
  `;
}
