import styled from "styled-components";
import { Social } from "../../common/Social";

export const Footer = () => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.TitleContainer>
            <S.Title>KLSH APARTMENT</S.Title>
            <S.Label>КУПИ КВАРТИРУ СВОЕЙ МЕЧТЫ</S.Label>
          </S.TitleContainer>
          <Social />
        </S.Content>
        <S.Divider />
      </S.Container>
    </S.Wrapper>
  );
};

namespace S {
  export const Wrapper = styled.div`
    background: #211e2e;
  `;

  export const Container = styled.div`
    display: flex;
    padding: 62px 8.889px 124.556px 8.889px;
    flex-direction: column;
    align-items: center;
    gap: 33.444px;
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 60px 0;
      gap: 48px;
    }
  `;

  export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 110px;

    @media (max-width: 768px) {
      gap: 48px;
    }
  `;

  export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    @media (max-width: 768px) {
      gap: 6px;
    }
  `;

  export const Title = styled.span`
    color: #e6e8ee;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  `;

  export const Label = styled.span`
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  `;

  export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.5);
  `;
}
