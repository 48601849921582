import styled from "styled-components";
import { useWindowSize } from "../../hooks/useWindowSize";
import arrowDown from "../../assets/images/arrowDown.svg";
import arrowUp from "../../assets/images/arrowUp.svg";
import { useRef, useState } from "react";

const DESCRIPTION = [
  {
    title: "Архитектор:",
    label: "Анастасия Придачина",
  },
  {
    title: "Площадь проекта:",
    label: "105 кв.м.",
  },
  {
    title: "Год проекта:",
    label: "2023",
  },
  {
    title: "Местоположение:",
    label: " ЖК. Зодиак; Могилев, Беларусь",
  },
];

export const About = () => {
  const { width } = useWindowSize();
  const [isExpanded, setIsExpanded] = useState(false);
  const descriptionRef = useRef<HTMLDivElement | null>(null);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
    if (descriptionRef.current) {
      if (!isExpanded) {
        descriptionRef.current.style.maxHeight = `${descriptionRef.current.scrollHeight}px`;
      } else {
        descriptionRef.current.style.maxHeight = "306px";
      }
    }
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.AboutContentContainer>
          {DESCRIPTION.map((item, index) => (
            <S.ContentContainer key={index}>
              <S.Title>{item.title}</S.Title>
              <S.Text>{item.label}</S.Text>
            </S.ContentContainer>
          ))}
        </S.AboutContentContainer>
        <S.Description ref={descriptionRef} $isExpanded={isExpanded}>
          Синематография как способ приручить геометрию.
          {width > 768 && (
            <>
              <br />
              <br />
            </>
          )}
          Данный объект находится в здании спиралевидной формы, где каждый этаж
          смещен на определенный градус по часовой стрелке. Изначальный объем
          представлял собой вытянутое пространство сложной геометрии с
          отсутствием прямых углов, а также наличием колонн в самых неожиданных
          местах. Преимуществом служили окна, открывающие взор на кроны
          деревьев. Конфигурация квартиры требовала сохранить воздух. Мы
          поддержали это ощущение и по сути разделили пространство на просторные
          публичный и приватный блоки с второстепенными помещениями-карманами.
          При желании их можно объединить продольной осью от самых дальних окон,
          чтобы получить глубокую перспективу. Нужно лишь распахнуть дверь.
          {width > 768 && (
            <>
              <br />
              <br />
            </>
          )}
          Каждый блок оснащен своей гардеробной и ванной комнатой. В
          мастер-блоке спальня соединяется с ванной стеклянными распашными
          дверьми, чтобы пустить свет к отдельностоящей ванны и себя в новый
          день. Использование разных цветовых схем позволило визуально
          объединить функцию: красный демонстрирует самые приватные места
          квартиры - душевую и ванную комнату, а белая перфорация определяет
          местоположение систем хранения.
          {width > 768 && (
            <>
              <br />
              <br />
            </>
          )}
          Работа с цветом базировалась на картинах Кубрика, творчество которого
          было определено как лидирующее для данной концепции.
          Приглушенно-красные пространства отдают дань первой сцене фильма
          «Сияние», в то время как сочетание белого фарфора на фоне фиолетового
          монолита уводит нас в бар Korova из «Заводного Апельсина». Далее нужно
          было увязать все зеленым, который пришел из визуала Гаспаро Ноэ. Пазл
          сошелся, и мы начали вплетать металлические плоскости, от нержавеющей
          стали и хромированных элементов до наливного пола с металлической
          пудрой и перфорации, мелкую плитку, графику и динамику. А объекты
          современного искусства и предметного дизайна раскрыли характер и
          добавили праздности пространству.
          <S.Arrow
            src={isExpanded ? arrowUp : arrowDown}
            onClick={toggleText}
          />
        </S.Description>
      </S.Container>
    </S.Wrapper>
  );
};

namespace S {
  export const Wrapper = styled.div`
    background-color: #211e2e;
  `;

  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 65px 130px;
    gap: 24px;
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 0px 16px 24px;
    }
  `;

  export const AboutContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    gap: 3px;

    @media (max-width: 768px) {
      margin: 0 -16px;
      background-color: #fff;
      padding: 24px 16px;
    }
  `;

  export const ContentContainer = styled.div`
    display: flex;
    gap: 5px;

    @media (max-width: 768px) {
      gap: 3px;
    }
  `;

  export const Text = styled.span`
    color: #fff;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;

    @media (max-width: 768px) {
      font-size: 12px;
      color: #3a3a3a;
    }
  `;

  export const Title = styled(Text)`
    font-weight: 400;
  `;

  export const Description = styled(Text)<{ $isExpanded: boolean }>`
    font-size: 20px;
    line-height: 30px;
    word-wrap: break-word;
    letter-spacing: -0.13px;
    display: flex;
    gap: 10px;
    overflow: hidden;
    transition: max-height 0.5s ease;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 18px;
      max-height: ${({ $isExpanded }) => ($isExpanded ? "100%" : "306px")};
      color: #fff;
    }
  `;

  export const Arrow = styled.img`
    display: none;
    width: 20px;
    height: 20px;

    @media (max-width: 768px) {
      display: flex;
      align-self: flex-end;
      font-size: 12px;
      line-height: 18px;
      color: #fff;
    }
  `;
}
