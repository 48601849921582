import styled from "styled-components";
import { Content } from "./Content";
import { data } from "./data";

export const ContentList = () => {
  return (
    <S.Container>
      {data.map((item, index) => (
        <Content
          title={item.title}
          images={item.images}
          description={item.description}
          key={index}
        />
      ))}
    </S.Container>
  );
};

namespace S {
  export const Container = styled.div`
    max-width: 1440px;
    margin: 0 auto;
  `;
}
