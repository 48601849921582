import styled from "styled-components";
import preview from "../../assets/images/preview.webp";
import previewSmall from "../../assets/images/previewSmall.webp";
import { Header } from "../../components/Header";
import { useWindowSize } from "../../hooks/useWindowSize";

export const Preview = () => {
  const { width } = useWindowSize();

  return (
    <S.Wrapper>
      <S.Container>
        <S.HeaderAbsolute />
        <S.Image
          loading="eager"
          alt="preview"
          src={width > 768 ? preview : previewSmall}
        />
        <S.TitleContainer>
          <S.Title>KLSH APARTMENT</S.Title>
          <S.Description>КУПИ КВАРТИРУ СВОЕЙ МЕЧТЫ</S.Description>
        </S.TitleContainer>
      </S.Container>
    </S.Wrapper>
  );
};

namespace S {
  export const Wrapper = styled.div`
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
  `;

  export const HeaderAbsolute = styled(Header)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @media (max-width: 768px) {
      display: none;
    }
  `;

  export const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: 768px) {
      max-width: 768px;
    }
  `;

  export const Image = styled.img`
    width: 100%;
  `;

  export const TitleContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      display: none;
    }
  `;

  export const Title = styled.h1`
    text-align: center;
    margin: 0;
    color: #fff;
    font-family: Montserrat;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  `;

  export const Description = styled.span`
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: uppercase;
  `;
}
