import styled from "styled-components";

export const Price = () => {
  return (
    <S.Container>
      <S.Text>125.000$</S.Text>
    </S.Container>
  );
};

namespace S {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 65px 0;
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 40px 0;
    }
  `;

  export const Text = styled.span`
    color: #211e2e;
    font-family: Montserrat;
    font-size: 145px;
    font-style: normal;
    font-weight: 900;
    font-variant: small-caps;
    line-height: 217.5px;
    word-wrap: break-word;

    @media (max-width: 768px) {
      font-size: 48px;
      line-height: 72px;
    }
  `;
}
