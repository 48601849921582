import styled from "styled-components";
import { Preview } from "./sections/Preview/Preview";
import "./styles.css";
import { About } from "./sections/About/About";
import { Plan } from "./sections/Plan/Plan";
import { ContentList } from "./sections/Content/ContentList";
import { Price } from "./sections/Price/Price";
import { Footer } from "./sections/Footer/Footer";
import { Header } from "./components/Header";
import { Video } from "./sections/Video/Video";
import { ResidentialComplex } from "./sections/ResidentialComplex/ResidentialComplex";

export const App = () => {
  return (
    <S.Wrapper>
      <S.HeaderContainer>
        <S.Title>KLSH APARTMENT</S.Title>
        <S.Description>КУПИ КВАРТИРУ СВОЕЙ МЕЧТЫ</S.Description>
        <S.Divider />
        <S.HeaderStyled />
      </S.HeaderContainer>
      <Preview />
      <About />
      <Plan />
      <ContentList />
      <Video />
      <ResidentialComplex />
      <Price />
      <Footer />
    </S.Wrapper>
  );
};

namespace S {
  export const Wrapper = styled.div`
    position: relative;
    margin: 0 auto;
  `;

  export const HeaderContainer = styled.div`
    display: none;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
    }
  `;

  export const Title = styled.h1`
    margin: 0;
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  `;

  export const Description = styled.span`
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    text-transform: uppercase;
  `;

  export const Divider = styled.div`
    width: 270px;
    height: 1px;
    background: rgba(0, 0, 0, 0.5);
    margin: 20px 0 10px;
  `;

  export const HeaderStyled = styled(Header)`
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-bottom: none;
  `;
}
