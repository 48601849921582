import styled from "styled-components";
import { useState, useRef, useCallback } from "react";

import arrowLeft from "../../assets/images/arrowLeft.svg";
import arrowRight from "../../assets/images/arrowRight.svg";
import arrowDownGray from "../../assets/images/arrowDownGray.svg";
import arrowUpGray from "../../assets/images/arrowUpGray.svg";

const TextWithBoldWords = (text: string) => {
  const textElements = text.split(" ").map((word, index) => {
    if (word.startsWith("!")) {
      const boldWord = word.substring(1);
      return <S.Bold key={index}>{boldWord} </S.Bold>;
    } else {
      return <span key={index}>{word} </span>;
    }
  });

  return <div>{textElements}</div>;
};

type ContentProps = {
  title: string;
  images: Array<string>;
  description: string;
};

export const Content = ({ title, images, description }: ContentProps) => {
  const [isDown, setIsDown] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const carouselWrapperRef = useRef<HTMLDivElement | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const descriptionRef = useRef<HTMLDivElement | null>(null);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
    if (descriptionRef.current) {
      if (!isExpanded) {
        descriptionRef.current.style.maxHeight = `${descriptionRef.current.scrollHeight}px`;
      } else {
        descriptionRef.current.style.maxHeight = "54px";
      }
    }
  };

  const scroll = useCallback((direction: "left" | "right") => {
    carouselWrapperRef?.current?.scrollBy({
      left: direction === "left" ? -580 : 580,
      behavior: "smooth",
    });
  }, []);

  const mouseMove = (e: React.MouseEvent) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - carouselWrapperRef.current!.offsetLeft;
    const walk = x - startX;

    carouselWrapperRef.current!.scrollLeft = scrollLeft - walk;
  };

  const mouseDown = (e: React.MouseEvent) => {
    setIsDown(true);
    setStartX(e.pageX - carouselWrapperRef.current!.offsetLeft);
    setScrollLeft(carouselWrapperRef.current!.scrollLeft);
  };

  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.ImagesWrapper>
        <S.ImagesContainer
          onMouseDown={mouseDown}
          onMouseLeave={() => setIsDown(false)}
          onMouseMove={mouseMove}
          onMouseUp={() => setIsDown(false)}
          ref={carouselWrapperRef}
        >
          {images.map((item, index) => (
            <S.ImageContainer key={index}>
              <S.Image
                loading="eager"
                src={item}
                alt={`${title}_${index + 1}`}
              />
            </S.ImageContainer>
          ))}
        </S.ImagesContainer>
        {images.length > 2 && (
          <S.ArrowContainer>
            <S.Arrow
              alt="arrow-right"
              onClick={() => scroll("right")}
              src={arrowRight}
            />
            <S.Arrow
              alt="arrow-left"
              onClick={() => scroll("left")}
              src={arrowLeft}
            />
          </S.ArrowContainer>
        )}
      </S.ImagesWrapper>
      <S.DescriptionContainer>
        <S.Description $isExpanded={isExpanded} ref={descriptionRef}>
          {TextWithBoldWords(description)}
        </S.Description>
        <S.ArrowDescription
          src={isExpanded ? arrowUpGray : arrowDownGray}
          onClick={toggleText}
          color="#4A4A4A"
        />
      </S.DescriptionContainer>
    </S.Container>
  );
};

namespace S {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 65px 0 65px 130px;
    gap: 40px;

    @media (max-width: 768px) {
      padding: 24px 0 24px 16px;
      gap: 0;
    }
  `;

  export const Title = styled.span`
    color: #000;
    font-family: Montserrat;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: normal;
      margin-bottom: 24px;
    }
  `;

  export const ImagesWrapper = styled.div`
    position: relative;
  `;

  export const ImagesContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: auto;
    gap: 20px;
    &::-webkit-scrollbar {
      display: none;
    }
    transition: transform 0.5s ease;

    @media (max-width: 768px) {
      gap: 8px;
      margin: 0 0 0 -16px;
      padding: 0 16px 0 16px;
    }
  `;

  export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 580px;
    height: 700px;

    @media (max-width: 768px) {
      width: 300px;
      height: 362px;
    }
  `;

  export const Image = styled.img`
    width: inherit;
    object-fit: cover;
  `;

  export const ArrowContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 26px;
    transform: translate(0, -50%);
    display: flex;
    gap: 20px;

    @media (max-width: 768px) {
      display: none;
    }
  `;

  export const Arrow = styled.img`
    width: 84px;
    height: 84px;
    cursor: pointer;
  `;

  export const DescriptionContainer = styled.div`
    display: flex;

    @media (max-width: 768px) {
      padding: 0 7px 0 0;
    }
  `;

  const Text = styled.span`
    color: #4a4a4a;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    text-transform: uppercase;
    max-width: 980px;
    line-height: 23.4px;
    word-wrap: break-word;

    @media (max-width: 768px) {
      margin-top: 8px;
      font-size: 12px;
      line-height: 18px;
    }
  `;

  export const Description = styled(Text)<{ $isExpanded: boolean }>`
    @media (max-width: 768px) {
      max-height: ${({ $isExpanded }) => ($isExpanded ? "100%" : "54px")};
      overflow: hidden;
      transition: max-height 0.5s ease;
    }
  `;

  export const Bold = styled(Text)`
    font-weight: 500;
  `;

  export const ArrowDescription = styled.img`
    display: none;
    width: 20px;
    height: 20px;
    fill: black;

    @media (max-width: 768px) {
      display: flex;
      align-self: flex-end;
    }
  `;
}
