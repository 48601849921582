import styled from "styled-components";
import YouTube from "react-youtube";

export const Video = () => {
  return (
    <S.Container>
      <S.YoutubeStyled
        videoId="XLXjCFTivC4"
        opts={{ width: "100%", height: "100%" }}
      />
    </S.Container>
  );
};

namespace S {
  export const Container = styled.div`
    padding: 65px 130px;
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 65px 16px 25px;
    }
  `;

  export const YoutubeStyled = styled(YouTube)`
    width: 100%;
    height: 700px;

    @media (max-width: 768px) {
      height: 200px;
    }
  `;
}
