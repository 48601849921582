import styled from "styled-components";

import instagram from "../assets/images/social/instagram.svg";
import telegram from "../assets/images/social/telegram.svg";
import whatsapp from "../assets/images/social/whatsapp.svg";
import viber from "../assets/images/social/viber.svg";

const ITEMS = [
  {
    image: instagram,
    alt: "instagram",
    href: "https://instagram.com/klsh_apartment",
  },
  {
    image: telegram,
    alt: "telegram",
    href: "https://t.me/k2jix",
  },
  {
    image: whatsapp,
    alt: "whatsapp",
    href: "https://wa.me/375293336111",
  },
  {
    image: viber,
    alt: "viber",
    href: "viber://chat?number=+375293336111/",
  },
];

export const Social = () => {
  return (
    <S.Container>
      {ITEMS.map((item, index) => (
        <a
          key={index}
          target={item.alt === "viber" ? "_self" : "_blank"}
          href={item.href}
          rel="noreferrer"
        >
          <S.SocialImage src={item.image} alt={item.alt} />
        </a>
      ))}
    </S.Container>
  );
};

namespace S {
  export const Container = styled.div`
    display: flex;
    gap: 20px;
  `;

  export const SocialImage = styled.img`
    width: 50px;
    height: 50px;

    @media (max-width: 768px) {
      width: 40px;
      height: 40px;
    }
  `;
}
